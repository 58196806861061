<template>

  <NuxtLink class="seed" :to="data.link">


    <div class="nm">{{ data.name }}</div>


    <div :class="'rw year ' + (release_year_calc ? '' : 'empty')" title="Release Year">
      <template v-if="release_year_calc">
        {{ release_year_calc }}
        <div class="new">new</div>
      </template>
      <template v-else-if="data.is_bestseller">
        <div class="best" title="Bestseller">
          <span>best</span>
          <span>seller</span>
        </div>
      </template>
    </div>

    <!-- <div :class="'rw fl ' + (data.flowering_min ? '' : 'empty')">
      {{ maxMin(data.flowering_min, data.flowering_max) }}
    </div> -->

    <!-- <div :class="'rw is ' + (data.indica_sativa ?? 'empty')">
      {{ gen(data.indica_sativa) ?? '' }}
    </div> -->

    <!--  -->

    <div :class="'rw tg ' + (data.props.seed_gender_type ?? 'empty')">
      <i v-if="data.props.seed_gender_type == 'fem'" class="icon-female" title="Fem"></i>
      <i v-if="data.props.seed_gender_type == 'reg'" class="icon-male" title="Reg"></i>
    </div>

    <div :class="'rw tf ' + (data.props.seed_flowering_type ?? 'empty')">
      <span  v-if="data.props.seed_flowering_type" :class="'shp ' + data.props.seed_flowering_type" :title="data.props.seed_flowering_type">
        {{ typeFlowering(data.props.seed_flowering_type) ?? '' }}
      </span>
      <span  v-if="data.props.seed_flowering_type" :class="'shph ' + data.props.seed_flowering_type" :title="data.props.seed_flowering_type">
        {{ data.props.seed_flowering_type ?? '' }}
      </span>
    </div>

    <div :class="'rw gps ' + (data.item_stat.avg_weight_plant ? '' : 'empty')">
      <template v-if="data.item_stat.avg_weight_plant">
        {{ data.item_stat.avg_weight_plant ? data.item_stat.avg_weight_plant : '' }} <span>g/p</span>
      </template>
    </div>
    
    <div :class="'rw dr ' + (data.item_stat.cnt_reports ? '' : 'empty')">
      <i v-if="data.item_stat.cnt_reports" class="icon-diary" :title="$t('universal_plurals_diaries')"></i>
      {{ data.item_stat.cnt_reports ? data.item_stat.cnt_reports : '' }}
    </div>

    <div :class="'rw rt'">
        <span v-if="data.item_stat.avg_rate" class="star_rate"></span>
        <span>{{ data.item_stat.avg_rate ? data.item_stat.avg_rate : '' }}</span>
    </div>



    
  </NuxtLink>

</template>

<script setup>
 

const { t } = useI18n();

const props = defineProps({
  data: {
    type: Object,
  }, 
})


const maxMin = (min, max) => {
  if(max && min){
    return min + ' - ' + max + ' weeks';
  }else if(max){
    return max + ' weeks';
  }else if(min){
    return min + ' weeks';
  }else{
    return '';
  }
}

const gen = (vl) => {
  if(vl == 0){
    return 'Indica'
  } else if(vl < 50){
    return 'Most Indica'
  } else if(vl == 50){
    return 'Indica/Sativa'
  } else if(vl > 100){
    return 'Most Sativa'
  } else if(vl == 100){
    return 'Sativa'
  } else {
    return ''
  } 
}


// first letter of each word
const typeGender = (vl) => {
  return vl[0];
}

// first letter of each word
const typeFlowering = (vl) => {
  return vl[0]
}


const release_year_calc = computed(() => {
  // if current and prev years 
  if(!props.data?.props?.release_year){
    return '';
  }else if(props.data.props.release_year >= new Date().getFullYear()-1){
    return props.data.props.release_year;
  }
  return '';
})

</script>

<style scoped>
 
  

.seed {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* width: 100%; */
  width: calc(100% - 1rem);
  gap: 0 1rem;
  border-bottom: 1px var(--un-element-color-gray-light) solid;
  break-inside: avoid;
}

.seed:last-child {
  border-bottom: 0;
}
.seed:hover{
  color: black;
}
.nm{
  width: 100%;
  padding: 4px 0;
  font-weight: 600;
}
.rw{
  display: block;
  flex-shrink: 0;
  width: 70px;
  align-items: center;
  gap: 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rw.year{
  width: 35px;
  font-size: 0.8rem;
  text-align: center;
}
.rw.year .new{
  display: block;
  border-radius: 2px;
  font-size: 0.45rem;
  text-transform: uppercase;
  margin: 0 auto;
  width: -moz-fit-content;
  width: 34px;
  padding: 0 3px;
  margin-top: -4px;
  line-height: 0.6rem;
  font-weight: bold;
  text-align: center;
}
.rw.year .best{
  color: #d44302;
  display: block;
  border-radius: 2px;
  font-size: 0.4rem;
  text-transform: uppercase;
  margin: 0 auto;
  width: -moz-fit-content;
  width: 34px;
  padding: 0 3px;
  line-height: 0.5rem;
  font-weight: bold;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.rw.year .best span:first-child{  
  font-size: 0.6rem;
  line-height: 0.6rem;
}
.rw.is{
  width: 100px;
}
.rw.fl{
  width: 90px;
}
.rw.gps{
  width: 60px;
}
.rw.gps span{
  font-size: 0.75rem;
}
.rw.tf,
.rw.tg{
  font-weight: 500;
  width: 40px;
  /* line-height: 0.6rem; */
  text-align: center;
}

/* type flowering */
.rw.tf .shp{
  display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    /* aspect-ratio: 1 / 1; */
    width: 16px;
    /* height: 10px; */
    font-size: 1.1rem;
    border-radius: 2px;
    font-family: Courier;
    text-transform: uppercase;
    line-height: 0.7rem;
    margin: 0 auto;
}
.rw.tf .shph{

  line-height: 0.5rem;
    font-size: 0.4rem;
    /* margin-top: -5px; */
    display: block;
    text-transform: uppercase;
    font-weight: bold;
}

.rw.tf .shph.auto,
.rw.tf .shp.auto{
  color: #1eb353;
}
.rw.tf .shph.photo,
.rw.tf .shp.photo{
  color: #a913d9;
} 
/* type gender */

.rw.tg i{
  font-size: 1.1rem;
}
.rw.tg.fem i{
  color: #ff0067;
}
.rw.tg.reg i{
  color: #0068ff;
} 

/*  */


 

.rw.tp .tpin{
  text-transform: uppercase;
    font-weight: 600;
    color: var(--gd-link-color);
    font-size: 0.7rem;
    border-bottom: 1px var(--gd-link-color) solid;
    margin-bottom: 2px;
    display: block;
    width: -moz-fit-content;
    width: fit-content;
    line-height: 0.8rem;
}
.rw.dr{
  width: 50px;
}
.rw.rt{
  display: flex;
  width: 70px;
}
.rw.rt .star_rate{

  width: 16px;
    height: 16px;
}
.rw.rt .max{
  color: rgb(160, 160, 160);
}
 
@container pb (max-width: 1000px) {
  .seed{
    width: 100%;
  }
}

@container pb (max-width: 800px) {
  .rw.fl{
    display: none;
  }
}
 
@container pb (max-width: 600px) {
  .rw.is{
    display: none;
  }
  .rw.year{
    display: none;
  }
}
 
@container pb (max-width: 500px) {
  /* .rw.dr{
    display: none;
  } */
}

@container pb (max-width: 400px) {
 
  .rw.tg{
    display: none;
  } 
  .rw.tf{
    display: none;
  } 
  /* .rw.dr{
    display: none;
  }  */
  .rw.fl{
    display: none;
  } 
  .rw.gps{
    display: block;
  }
 
  .rw.is{
    display: none;
  }
  .rw.rt{
    justify-self: flex-end;
    width: 40px;
    font-weight: 600;
  }
  .rw.year{

  }
  .rw.year,
  .rw.dr,
  .rw.fl,
  .rw.is{
    min-width: initial;
    margin-right: 10px;
  }

  /* .rw.empty{
    display: none;
  }

  .rw.rt .max{
    display: none;
  } */

  /* .rw.year::after,
  .rw.gps::after,
  .rw.dr::after,
  .rw.fl::after,
  .rw.is::after{
    content: ', ';
    
  } */

  .nm{
    padding: 2px 0;
  }

  .seed{
    padding-bottom: 2px;
    margin-bottom: 2px;
    gap: 0 5px;
  }

}




</style>
