<template>

  <div class="pros">

    <h2 class="divider-sec title">
      <nuxt-link class="" :to="link">
        {{ title }} 
      </nuxt-link>
      <!-- <nuxt-link class="h2_show_all" :to="link">{{ showmore }}</nuxt-link> -->
    </h2>

 
    <div class="tags">
      <GeneralSearchTags 
        :tags="props.intags" 
        :tagsselected="tagsselected" 
        :tagsdefault="props.intags" 
        :tagscount="product_data?.items_tags"    
        @choose="chooseTags"
        @unchoose="unchooseTags"
        />
    </div>
 
    <div class="sort">
      <UiSort 
        @choose="chooseSort"
        :val="sort"
        :align="'right'"
        :options="[
          {id:'az_desc', name: $t('universal_sort_az'), icon: 'desc'},
          {id:'az_asc', name: $t('universal_sort_az'), icon: 'asc'},
          {id:'rate_desc', name: $t('universal_sort_rating'), icon: 'desc'},
          {id:'rate_asc', name: $t('universal_sort_rating'), icon: 'asc'},
          {id:'gplant_desc', name: $t('universal_sort_gplant'), icon: 'desc'},
          {id:'gplant_asc', name: $t('universal_sort_gplant'), icon: 'asc'},
          {id:'diaries_desc', name: $t('universal_sort_diaries'), icon: 'desc'},
          {id:'diaries_asc', name: $t('universal_sort_diaries'), icon: 'asc'},
        ]"
        />
    </div>

    <div class="proslist"> 
      <template v-for="pro in product_data?.items">
        <BrandProductListItem
          :data="pro"
          />
      </template>
    </div>
  </div>

</template>

<script setup>

import { watchArray } from '@vueuse/core'

const {$api, $adv, $tagsUtil, $ga} = useNuxtApp()

const props = defineProps({
  title: {
    type: String,
    default: 'Title'
  },
  showmore: {
    type: String,
    default: 'Show all'
  },
  link: {
    type: String,
    default: '/'
  },
  brandId: {
    type: Number,
    required: true
  },
  limit: {
    type: Number,
    default: 10
  },
  products: {
    type: Array,
    default: []
  },
  intags: {
    type: Object,
    required: true
  }, 
  inurl: {
    type: String,
    required: true
  },
})

const is_ended = ref(false)
const start = ref(0);
const limit = ref(300);
const sort = ref('az_asc');
const tagsselected = ref([]);
const tagspermanent = ref([]);

if(props.brandId){
  tagspermanent.value.push('br:' + props.brandId);
}

if(props.brandId && (useAuth().isTester(props.brandId) || useAuth().getAccess('view_extra'))){
}else{
  tagspermanent.value.push('not_tests');
}

const unchooseTags = function(selected){  
  tagsselected.value = tagsselected.value.filter((item) => item != selected);
}        
const chooseTags = function(selected){       
  tagsselected.value.push(selected);      
}

const chooseSort = function(selected_sort){   
  sort.value = selected_sort;    
}



const loadData = async function() {     
  const response = await $api.getProducts({
    category: 'seed',
    start: start.value,
    limit: limit.value,
    q: $tagsUtil.getTagsKeyword(props.intags, tagsselected.value, tagspermanent.value),       
    sortable: sort.value,
    tagsall: $tagsUtil.getTagsKeys(props.intags),
    tags: $tagsUtil.getTagsSimple(props.intags, tagsselected.value),
    tagsper: $tagsUtil.getTagsSimple(props.intags, tagspermanent.value)
  });
  return response;  
}




const { pending: is_loading,  data: product_data } = await useLazyAsyncData('product_data', async () => await loadData())


watchArray([start, sort, tagsselected], async ([new_start, new_sort, new_tagsselected], added, removed) => {
  
  if(new_start == 0){
    product_data.value.items = [];
    is_ended.value = false;
  }
  
  console.log('watcher');
  is_loading.value = true;
  var dt = await loadData();

    
  if(dt.items?.length == 0)
    is_ended.value = true;  

  product_data.value.items = [...product_data.value.items, ...dt.items]
  product_data.value.items_tags = dt.items_tags
  is_loading.value = false;

  // $adv.apply();

}, {deep: true})

 
</script>




<style scoped>
 

.pros {
  width: 100%;
  display: grid;
  grid-template-areas:
    "title title"
    "tags sort"
    "proslist proslist";
  grid-template-columns: auto 150px;
  gap: 0.3rem;
  margin-bottom: 2rem
}

.title{
  grid-area: title;
}

.tags{
  grid-area: tags;
}

.sort{
  grid-area: sort;
  justify-content: flex-end;
}
.sort > div{
  margin: 0;
}
 
 

.proslist {
  grid-area: proslist;
  gap: 0 2rem;
  column-count: 2;
}


.proslist.centered {
  justify-content: center;
}


@container pb (max-width: 800px) {

  .pros {
    width: 100%;
    display: grid;
    grid-template-areas:
      "title sort"
      "tags tags"
      "proslist proslist";
    grid-template-columns: auto 150px;
    gap: 0.3rem;
  }

  .sort > div{
    padding: 1rem 0;
  }


  .proslist {
    column-count: 1;
  }

}

</style>
